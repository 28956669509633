@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Default Light Mode */
:root {
  --table-header-bg: #ffffff;
  --table-header-text: #1f2937;
  --table-row-bg: #ffffff;
  --table-row-text: #1f2937;
  --table-row-hover-bg: #f3f4f6;
  --table-pagination-bg: #ffffff;
  --table-pagination-text: #1f2937;
}

/* Dark Mode */
.dark {
  --table-header-bg: #292E32;
  --table-header-text: #d1d5db;
  --table-row-bg: #1A1D21;
  --table-row-text: #d1d5db;
  --table-row-hover-bg: #212529;
  --table-pagination-bg: #292E32;
  --table-pagination-text: #d1d5db;
}


body {
    margin: 0;
    font-family: 'IBM Plex Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 255, 0.2);
  border-top-color: #4a90e2;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}

.api-section pre {
  background-color: #1e1e1e;
  color: '#dcdcdc';
  padding: 8px;
  border-radius: 4px;
  overflow: auto !important;
  /* white-space: pre-wrap; */
}

.MuiPickersDay-root {
  font-size: 14px;
}

.MuiOutlinedInput-root {
  border-radius: .375rem !important;
}

.MuiInputBase-input {
  padding: .5rem !important
}

.goldtech-content {
  background-color: #F1F1F1;
}

.topbar .bx {
  color: #878a99 !important;
}

.dark .topbar {
  @apply bg-darkTopbar text-darkText;
  border-bottom: none;
}

.dark .sidebar {
  @apply bg-darkSidebar text-darkText;
}

.dark .footer {
  @apply bg-darkFooter text-darkText;
  border-top: none;
}

.dark .goldtech-content {
  @apply bg-darkContent text-darkText;
}

.dark .widget {
  @apply bg-darkWidgets text-darkText;
}

.dark .dropdown-widget {
  @apply bg-darkDropDown text-darkText;
}

.dark input,
.dark select,
.dark textarea {
  background-color: #282B2E;
  color: #ced4da;
  border: 1px solid #444B50;
}
.border {
  border-color: rgb(229 231 235 / 1) !important;
}
.dark .border, .dark .border-b, .dark .border-t {
  border-color: #444B50 !important;
}

.dark label {
  color:#dfedf5 !important;
}

.dark .secondary-text {
  color: #878a99;
}

.dark .main-text {
  color: #ced4da;
}

.card-animate {
  -webkit-transition: all .4s;
  transition: all .4s
}

.card-animate:hover {
  -webkit-transform: translateY(calc(-1.5rem / 5));
  transform: translateY(calc(-1.5rem / 5));
  -webkit-box-shadow: 0 5px 10px rgba(30,32,37,.12);
  box-shadow: 0 5px 10px rgba(30,32,37,.12)
}

.menu-title {
  letter-spacing: .05em;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: #a4b0bf;
  font-weight: 600;
}

.main-link {
  font-size: .9375rem;
  font-family: "IBM Plex Sans", sans-serif;
}